// Generated by Framer (ccd740c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {jZuvbaXRy: {hover: true}};

const cycleOrder = ["jZuvbaXRy"];

const variantClassNames = {jZuvbaXRy: "framer-v-gsxbp"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; link?: string; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "jZuvbaXRy", link: aVshXwEfy, image: inCwIacoe = {src: new URL("assets/512/raGaKQ8a0jJH6r4wqpFejVgUH4I.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/raGaKQ8a0jJH6r4wqpFejVgUH4I.jpg", import.meta.url).href} 512w, ${new URL("assets/1024/raGaKQ8a0jJH6r4wqpFejVgUH4I.jpg", import.meta.url).href} 1024w, ${new URL("assets/2048/raGaKQ8a0jJH6r4wqpFejVgUH4I.jpg", import.meta.url).href} 2048w, ${new URL("assets/4096/raGaKQ8a0jJH6r4wqpFejVgUH4I.jpg", import.meta.url).href} 4096w, ${new URL("assets/raGaKQ8a0jJH6r4wqpFejVgUH4I.jpg", import.meta.url).href} 6000w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "jZuvbaXRy", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-ilvHF", classNames)} style={{display: "contents"}}>
<Link href={aVshXwEfy}><Image {...restProps} as={"a"} background={{alt: "", fit: "fill", intrinsicHeight: 4000, intrinsicWidth: 6000, pixelHeight: 4000, pixelWidth: 6000, ...toResponsiveImage(inCwIacoe)}} className={`${cx("framer-gsxbp", className)} framer-8r3xhi`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"jZuvbaXRy"} ref={ref} style={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, filter: "saturate(0.46)", WebkitFilter: "saturate(0.46)", ...style}} transition={transition} {...addPropertyOverrides({"jZuvbaXRy-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-1escy66"} layoutDependency={layoutDependency} layoutId={"Z2XvgRC0z"} style={{backgroundColor: "rgb(255, 255, 255)"}} transition={transition}/></Image></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-ilvHF [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ilvHF * { box-sizing: border-box; }", ".framer-ilvHF .framer-8r3xhi { display: block; }", ".framer-ilvHF .framer-gsxbp { height: 357px; overflow: hidden; position: relative; text-decoration: none; width: 475px; will-change: transform; }", ".framer-ilvHF .framer-1escy66 { bottom: 0px; flex: none; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; }", ".framer-ilvHF .framer-v-gsxbp .framer-gsxbp { cursor: pointer; }", ".framer-ilvHF.framer-v-gsxbp.hover .framer-gsxbp { aspect-ratio: 1.3333333333333333 / 1; height: var(--framer-aspect-ratio-supported, 357px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 356.5
 * @framerIntrinsicWidth 475
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"YRjS22iIZ":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"aVshXwEfy":"link","inCwIacoe":"image"}
 */
const FramerdIR1YfyJX: React.ComponentType<Props> = withCSS(Component, css, "framer-ilvHF") as typeof Component;
export default FramerdIR1YfyJX;

FramerdIR1YfyJX.displayName = "lien site web image";

FramerdIR1YfyJX.defaultProps = {height: 356.5, width: 475};

addPropertyControls(FramerdIR1YfyJX, {aVshXwEfy: {title: "Link", type: ControlType.Link}, inCwIacoe: {__defaultAssetReference: "data:framer/asset-reference,raGaKQ8a0jJH6r4wqpFejVgUH4I.jpg?originalFilename=unrecognized-it-engineer-find-and-troubleshooting-2021-10-06-15-28-41-utc.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerdIR1YfyJX, [])